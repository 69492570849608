<template>
  <v-container>
    <v-col class="mb-4">
      <h1 class="display-2 font-weight-bold mb-3">Актуализация стеков разработки</h1>
      <p>Выберите стеки с которыми работает ваша компания. Если какого стека нет, но вы хотите по нему получать запросы, то укажите в поле  в конце страницы. </p>
    </v-col>

    <v-row>
      <v-col cols="4" class="mb-4" v-for="(n , i) in categories" :key="i" style="padding:0px; margin-bottom: 0px">
        <v-checkbox v-model="dt.rl" :value="n"  :label="n" style="margin-top: 0px; padding-top: 0px;"></v-checkbox>
      </v-col>
      
    </v-row>
    <v-row>
      <v-col
        cols="12"
      >
        <v-textarea
          solo
          name="input-7-4"
          v-model = "dt.txt"
          label="Дополнительные стеки разработки которых нет в списке"
        ></v-textarea>
      </v-col>
      
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn color="primary" class="mr-4" @click="onSave">
          Сохранить
        </v-btn>
      </v-col>
    </v-row>
    
  </v-container>
</template>

<script>
export default {
  data: () => ({
   id: 0,
   dt: {
    rl: [],
    txt: '',
   },
  }),
  name: "rolefirm",
  mounted() {
    this.id = this.$route.params.id;
    this.$store.dispatch("getCategories");
  },
  methods: {

    onSave() {
      let t = {
        rl: this.dt.rl,
        tx: this.dt.txt,
        id: this.id
      }
      this.$store.dispatch("setCategoriesFirm", t).then(r=>{
        if(r){
          alert("Благодарим вас за потраченное время! Мы сохранили ваш ответ теперь мы будем подбирать вам запросы более Подходящие вашему стеку. Можете закрыть страницу.")
        } else {
          alert("Упс что то пошло не так обратитесь за помощью @MolocayIsh")
        }
      });
    },
  },
  computed: {
    categories() {
      return this.$store.getters.getListCategories;
    },
    
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
