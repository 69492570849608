<template>
    <v-container v-if="GoodRegister">
        <v-row>
            <v-col col="12">
                <h2>
                    На ваш email отправлена ссылка для подтверждения почты. Перейдите по ней для активации доступа в личный
                    кабинет. Если ссылка на активацию не пришла на ваш емайл можете получить ссылку через наш telegram бота https://t.me/brainicsSupportBot. Для зайдите на нашего бота и запустите /activation. 
                    кабинет. <br />Если не приходит уведомление на почту можете воспользоваться нашим телеграм ботом для активации кабинета <a href="https://t.me/brainicsSupportBot">https://t.me/brainicsSupportBot</a> запустите его и выберите команду /activation.
                </h2>
                
            </v-col>
        </v-row>
    </v-container>
    <v-container v-else>
        <v-alert density="compact" color="red" type="warning" title="Alert title">
            Внимание! Нам интересны только ваши штатные специалисты - не преоффер, не ребята от ваших партнеров нам не интересны. Учитывайте это при работе. <br />
            Под штатным специалистом понимается сотрудник, у которого с вами прямой контракт и ваша компания - это основное место его фактической работы. При этом он может быть ИП, Самозанятым или оформлен по трудовому договору или договору ГПХ
    </v-alert>
        <v-col class="mb-4">
            <h4 class="display-1 font-weight-bold mb-3">Ответьте пожалуйста на несколько вопросов и регистрация будет завершена.
            </h4>
        </v-col>

        <v-row>
            <v-col cols="4" class="mb-4"></v-col>
            <v-col cols="4" class="mb-4">
                <v-form ref="form" lazy-validation v-model="valid">
                    <v-text-field label="Сколько ит специалистов у вас в штате? (Количество)" type="number" :rules="numberValid" required
                        v-model="data.countWorkes"></v-text-field>
                        <v-radio-group v-model="data.docYesNo" required :rules="RadioValid">
                        <template v-slot:label>
                            <div class="font-weight-bold mb-3">Вы сможете документально подтвердить наличие договора со специалистом? (Да, нет)

                            </div>
                        </template>
                        <v-radio value="q1">
                            <template v-slot:label>
                                <div>Да</div>
                            </template>
                        </v-radio>
                        <v-radio value="q2">
                            <template v-slot:label>
                                <div>Нет</div>
                            </template>
                        </v-radio>
                    </v-radio-group>
                    <v-text-field style="display: none;" label="Средняя ставка Middle (руб/час)" type="number" :rules="numberValid" required
                        v-model="data.RateMiddle"></v-text-field>
                    <v-text-field style="display: none;" label="Средняя ставка Senior (руб/час)" type="number" :rules="numberValid" required
                        v-model="data.RateSenior"></v-text-field>

                    <v-radio-group style="display: none;" v-model="data.timeReaction" required :rules="RadioValid">
                        <template v-slot:label>
                            <div class="font-weight-bold mb-3">Какое время реакции на наше сообщение в рабочем чате вы
                                считаете приемлемым?
                            </div>
                        </template>
                        <v-radio value="q1">
                            <template v-slot:label>
                                <div>5-10 минут</div>
                            </template>
                        </v-radio>
                        <v-radio value="q2">
                            <template v-slot:label>
                                <div>15-30 минут</div>
                            </template>
                        </v-radio>
                        <v-radio value="q3">
                            <template v-slot:label>
                                <div>как появится свободное время</div>
                            </template>
                        </v-radio>
                    </v-radio-group>
                    <v-radio-group v-model="data.testPeriod" required :rules="RadioValid" style="display: none;">
                        <template v-slot:label>
                            <div class="font-weight-bold mb-3">Готовы ли Вы предоставить тестовый период в рамках первого
                                месяца работы специалиста на
                                проекте (В течение тестового периода клиент имеет право отказаться от работы со специалистом
                                в любой день)?

                            </div>
                        </template>
                        <v-radio value="q1">
                            <template v-slot:label>
                                <div>Да</div>
                            </template>
                        </v-radio>
                        <v-radio value="q2">
                            <template v-slot:label>
                                <div>Нет</div>
                            </template>
                        </v-radio>
                        <v-radio value="q3">
                            <template v-slot:label>
                                <div>Хочу узнать подробности</div>
                            </template>
                        </v-radio>
                    </v-radio-group>
                    <v-radio-group  style="display: none;" v-model="data.document" required :rules="RadioValid">
                        <template v-slot:label>
                            <div class="font-weight-bold mb-3">Готовы ли Вы рассмотреть и подписать НДА и Договор с нами в
                                течение недели?

                            </div>
                        </template>
                        <v-radio value="q1">
                            <template v-slot:label>
                                <div>Да</div>
                            </template>
                        </v-radio>
                        <v-radio value="q2">
                            <template v-slot:label>
                                <div>Нет</div>
                            </template>
                        </v-radio>
                        <v-radio value="q3">
                            <template v-slot:label>
                                <div>Хочу узнать подробности</div>
                            </template>
                        </v-radio>
                    </v-radio-group>
                    <v-radio-group v-model="data.realExp" required :rules="RadioValid">
                        <template v-slot:label>
                            <div class="font-weight-bold mb-3">Есть ли у вашей компании реальный опыт заказной разработки

                            </div>
                        </template>
                        <v-radio value="q1">
                            <template v-slot:label>
                                <div>Да</div>
                            </template>
                        </v-radio>
                        <v-radio value="q2">
                            <template v-slot:label>
                                <div>Нет</div>
                            </template>
                        </v-radio>
                    </v-radio-group>
                    <v-radio-group required :rules="RadioValid">
                        <template v-slot:label>
                            <div class="font-weight-bold mb-3">Вы работаете с НДС?

                            </div>
                        </template>
                        <v-radio value="q1">
                            <template v-slot:label>
                                <div>Да</div>
                            </template>
                        </v-radio>
                        <v-radio value="q2">
                            <template v-slot:label>
                                <div>Нет</div>
                            </template>
                        </v-radio>
                    </v-radio-group>
                    <v-text-field v-if="data.realExp == 'q1'" label="ссылка на портфолио" required
                        v-model="data.linkZakProject"></v-text-field>
                    <v-radio-group style="display: none;" v-model="data.productExp" required :rules="RadioValid">
                        <template v-slot:label>
                            <div class="font-weight-bold mb-3">Есть ли у Вас успешный опыт создания собственного продукта?
                            </div>
                        </template>
                        <v-radio value="q1">
                            <template v-slot:label>
                                <div>Да</div>
                            </template>
                        </v-radio>
                        <v-radio value="q2">
                            <template v-slot:label>
                                <div>Нет</div>
                            </template>
                        </v-radio>
                    </v-radio-group>
                    <v-text-field  style="display: none;" v-if="data.productExp == 'q1'" label="ссылка на продукт" required
                        v-model="data.linkYoungProject"></v-text-field>
                    <v-radio-group>
                        <template v-slot:label>
                            <div class="font-weight-bold mb-3">Укажите индустрии, в которых у Вас есть кейсы: список</div>
                        </template>
                        <v-autocomplete :items="industries" v-model="data.industriesList" :rules="ListValid" multiple
                            required dense chips small-chips persistent-hint label="Выберите индустрии"></v-autocomplete>
                    </v-radio-group>
                    <v-radio-group>
                        <template v-slot:label>
                            <div class="font-weight-bold mb-3">Укажите основные стеки технологий Вашей компании: список</div>
                        </template>
                        <v-autocomplete :items="categories" v-model="data.skillList" :rules="ListValid" dense chips small-chips
                            required persistent-hint label="Роль*" multiple></v-autocomplete>
                    </v-radio-group>
                    <v-radio-group v-model="data.timePersonList" required :rules="RadioValid">
                        <template v-slot:label>
                            <div class="font-weight-bold mb-3">Вы готовы быстро (до 15 минут) предоставить данные по специалисту (ФИО, ДР, даты отпуска, актуальность, слоты для собеседования)? </div>
                        </template>
                        <v-radio value="q1">
                            <template v-slot:label>
                                <div>Да, конечно</div>
                            </template>
                        </v-radio>
                        <v-radio value="q2">
                            <template v-slot:label>
                                <div>Нет, потребуется минут 30</div>
                            </template>
                        </v-radio>
                        <v-radio value="q3">
                            <template v-slot:label>
                                <div>Только если в течение дня</div>
                            </template>
                        </v-radio>
                        <v-radio value="q4">
                            <template v-slot:label>
                                <div>В зависимости от того как быстро ответит работодатель специалиста</div>
                            </template>
                        </v-radio>
                    </v-radio-group>
                    <v-radio-group style="display: none;" v-model="data.badExp" required :rules="RadioValid">
                        <template v-slot:label>
                            <div class="font-weight-bold mb-3">Вы получили от нас негативный фидбек по работе вашего
                                специалиста,
                                ваш ответ:</div>
                        </template>
                        <v-radio value="q1">
                            <template v-slot:label>
                                <div>Мы даем вам специалиста в аренду и его качество работы зависит от того как ему ставятся
                                    задачи на проекте</div>
                            </template>
                        </v-radio>
                        <v-radio value="q2">
                            <template v-slot:label>
                                <div>Что случилось? Давайте разбираться и решать проблему, постараемся все исправить</div>
                            </template>
                        </v-radio>
                    </v-radio-group>
                    <v-radio-group style="display: none;" v-model="data.salePerson" required :rules="RadioValid">
                        <template v-slot:label>
                            <div class="font-weight-bold mb-3">Готовы ли вы предоставить скидку при обоснованном торге?
                            </div>
                        </template>
                        <v-radio value="q1">
                            <template v-slot:label>
                                <div>Да</div>
                            </template>
                        </v-radio>
                        <v-radio value="q2">
                            <template v-slot:label>
                                <div>Нет</div>
                            </template>
                        </v-radio>
                    </v-radio-group>
                    <v-radio-group v-model="data.paySpec" required :rules="RadioValid">
                        <template v-slot:label>
                            <div class="font-weight-bold mb-3">Мы оплачиваем работу специалиста на конкретном проекте сразу после получения оплаты от клиента. Если Вам подходит такой вариант - выбирайте “да”.</div>
                        </template>
                        <v-radio value="q1">
                            <template v-slot:label>
                                <div>Да</div>
                            </template>
                        </v-radio>
                        <v-radio value="q2">
                            <template v-slot:label>
                                <div>Нет
                                </div>
                            </template>
                        </v-radio>
                    </v-radio-group>
                    <v-radio-group  style="display: none;" v-model="data.newWork" required :rules="RadioValid">
                        <template v-slot:label>
                            <div class="font-weight-bold mb-3">тз
                                Иногда, после нескольких месяцев работы по аутстаффу, клиент готов "выкупить" вашего специалиста в свой штат, конечно, при условии, что самому специалисту нравится работать с клиентом и он готов стать частью его команды. Подскажите, пожалуйста, рассматриваете ли Вы такой вариант?

                            </div>
                        </template>
                        <v-radio value="q1">
                            <template v-slot:label>
                                <div>Да</div>
                            </template>
                        </v-radio>
                        <v-radio value="q2">
                            <template v-slot:label>
                                <div>Нет</div>
                            </template>
                        </v-radio>
                        <v-radio value="q3">
                            <template v-slot:label>
                                <div>В зависимости от условий</div>
                            </template>
                        </v-radio>
                    </v-radio-group>
                </v-form>
            </v-col>
            <v-col cols="4" class="mb-4"> </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-btn color="#FFE816" class="mr-4" @click="saveQuiz" :disabled="dis">
                    Зарегистрироваться
                </v-btn>
                <v-progress-circular indeterminate color="#FFE816" v-if="dis"></v-progress-circular>
            </v-col>

        </v-row>
        <v-bottom-sheet v-model="sheet" inset>
            <v-sheet class="text-center" height="200px">
                <v-btn class="mt-6" text color="error" @click="sheet = !sheet">
                    Закрыть
                </v-btn>
                <div class="my-3">
                    Не удалось сохранить попробуйте позже!
                </div>
            </v-sheet>
        </v-bottom-sheet>
    </v-container>
</template>
  
<script>
export default {
    data: () => ({
        GoodRegister: false,
        valid: false,
        dis: false,
        sheet: false,
        data: {
            valid: true,
            countWorkes: "",
            docYesNo: "",
            RateMiddle: "9999",
            RateSenior: "9999",
            timeReaction: 'q1',
            testPeriod: "q1",
            realExp: "",
            productExp: "q1",
            industriesList: "",
            skillList: "",
            timePersonList: "",
            badExp: "q1",
            salePerson: "q1",
            paySpec: "",
            linkZakProject: "",
            linkYoungProject: "old quez",
            document: "q1",
            newWork: "q2",

        },
        numberValid: [
            v => !!v || 'Значение не может быть 0',
            v => (v && v > 0) || 'Значение не может быть отрицательным или 0',
        ],
        RadioValid: [
            v => !!v || 'Значение должно быть выбрано',
        ],
        ListValid: [
            v => !!v || 'Должно быть выбрано хотябы одно значение',
        ],
    }),
    name: "login",
    mounted() {
        this.$store.dispatch("getIndustriesList");
        this.$store.dispatch("getSkillsList");

    this.$store.dispatch("getCategories");
    },
    methods: {

        async saveQuiz() {

            const validForm = await this.$refs.form.validate();
            console.log(validForm)
            if (this.data.industries != [] && this.data.skillList != [] && this.data.countWorkes != 0 && this.data.RateMiddle != 0 && this.data.RateSenior != 0 && validForm) {
                this.dis = true;
                this.$store.dispatch("setNewUser", this.data).then(res => {
                    if (res) {
                        this.valid = true;
                        this.GoodRegister = true;
                    }
                });
            }

        }

    },
    computed: {
        industries() {
            return this.$store.getters.getListIndustries;
        },
        skills() {
            return this.$store.getters.getListSkills;
        },
        categories() {
      return this.$store.getters.getListCategories;
    },
    },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
  