import Vue from "vue";
import Vuex from "vuex";
import Axios from "axios";
import Admin from "./module/admin.js"
import Main from "./module/main.js"
import Person from "./module/person.js"

Vue.use(Vuex);

class User {
  constructor(id) {
    this.id = id;
  }
}

export default new Vuex.Store({
  state: {
    user: null,
    avt: false,
    load: false,
    ListSpecialist: [],
    ListError: [],
    ListLocation: ['Россия','Беларусь','Армения', 'Казахстан'],
    ListLocationAct: [],
    ListTaxId:[],
    ListLocationEdit: [],
    ListLang: [],
    ListLangEdit: [],
    ListLangLevel: [],
    ListLangLevelEdit: [],
    ListLevel: [],
    ListLevelEdit: [],
    ListRole: [],
    ListRoleEdit: [],
    ListRate: ["1000", "1300", "1400", "1500"],
    ListSkills: [],
    ListSkillsEdit: [],
    ListPub: ["Опубликовано", "Черновик"],
    ListTypeCompany: [],
    ListTypeCompanyEdit: [],
    ListExp: [],
    ListCompany: [],
    ListCompanyEdit: [],
    ListPeopleCount: [],
    ListPeopleCountEdit: [],
    ListFormOrg: [],
    ListFormOrgEdit: [],
    ListTimeZoneEdit: [],
    ListTimeZone: [],
    ListCategoriesEdit: [],
    ListCategories: [],
    ListIndustries: [],
    ListIndustriesEdit: [],
    ListLevelEducation: [],
    ListLevelEducationEdit: [],
    ListEducation: [],
    ListEducationEdit: [],
    ListContryTimeZone: [],
    ListMenu: [],
    ListHeaders: [],
    PersonDataCV: [],
    StatusUser: [],
    CompanyData: [],
    baseUrl: Axios.defaults.baseURL + "/public/images/",
  },
  modules: {
    Admin,
    Main,
    Person,
  },
  mutations: {
    setAuth(state, payload) {
      state.avt = payload;
    },
    setUserAuth(state, payload) {
      state.user = payload;
    },
    UpdateListError(state, payload){
      state.ListError = payload;
    },
    UpdateMenuList(state, payload) {
      state.StatusUser = payload;
      if (payload > 3 ) {
        state.ListHeaders = [
          { text: "id", value: "id" },
          {
            text: "Имя",
            value: "FIO",
          },
          { text: "Фирма", value: "nameFirm" },
          { text: "Гражданство", value: "Country" },
          { text: "Английский", value: "English" },
          { text: "Уровень", value: "nameLevel" },
          { text: "Роль", value: "Role" },
          { text: "Ставка", value: "Rate_RUB" },
          { text: "Навыки", value: "Main_skills" },
          { text: "Статус CV", value: "st" },
          { text: "Доступен с", value: "Lastcheck" },
        ];
        if(payload == 6) {
          state.ListMenu = [
            { title: "Специалисты", icon: "mdi-account-settings", to: "/admspec",
            dis: false },
            { title: "Запросы", icon: "mdi-account-settings", to: "/admin/request",
            dis: false },
            { title: "Отклики на запросы", icon: "mdi-account-settings", to: "/admin/requestanswer",
            dis: false },
            { title: "CV", icon: "mdi-account-settings", to: "/cabinet/CV",
            dis: false },
            { title: "Телеграм", icon: "mdi-account-settings", to: "/admin/meneger",
            dis: false },

            {
              title: "профиль",
              icon: "mdi-checkbox-marked-circle",
              to: "/adm/profile",
              dis: false
            },

            {
              title: "Календарь",
              icon: "mdi-checkbox-marked-circle",
              to: "/adm/calendar",
              dis: false
            },
            {
              title: "Рабочий процесс (Сделки)",
              icon: "mdi-checkbox-marked-circle",
              to: "/admin/deals",
              dis: false
            },
            {
              title: "Компании",
              icon: "mdi-checkbox-marked-circle",
              to: "/company",
              dis: false
            },
            {
              title: "телеграм чаты",
              icon: "mdi-checkbox-marked-circle",
              to: "/admin/setting/send",
              dis: false
            },
            {
              title: "Синхронизация",
              icon: "mdi-checkbox",
              to: "/admin/synchronization",
              dis: false
            },
            {
              title: "интервью",
              icon: "mdi-checkbox",
              to: "/admin/interview",
              dis: false
            },

            {
              title: "Отчет",
              icon: "mdi-checkbox",
              to: "/admin/deal",
              dis: false
            },
            {
              title: "фонды",
              icon: "mdi-checkbox",
              to: "/admin/fonds",
              dis: false
            },
            {
              title: "Транзакции",
              icon: "mdi-checkbox",
              to: "/admin/dds",
              dis: false
            },
            {
              title: "Отчет по регистрации",
              icon: "mdi-checkbox",
              to: "/admin/log/reg",
              dis: false
            },
            {
              title: "настройка данных",
              icon: "mdi-checkbox",
              to: "/admin/setting/typeorg",
              dis: false
            },
            {
              title: "настройка данных по инн",
              icon: "mdi-checkbox",
              to: "/admin/setting/typeinn",
              dis: false
            },
            {
              title: "Фирмы",
              icon: "mdi-checkbox",
              to: "/admin/firm",
              dis: false
            },
             {
              title: "Боты",
              icon: "mdi-checkbox",
              to: "/admin/bots",
              dis: false
            },
          ];
        }
        else {
          if(payload == 7) {
            state.ListMenu = [
              { title: "Проекты", icon: "mdi-account-settings", to: "/project" },
              
              {
                title: "профиль",
                icon: "mdi-checkbox-marked-circle",
                to: "/setting",
              }
            ];
          } else {
          state.ListMenu = [
            { title: "Навыки", icon: "mdi-clippy", to: "/skills", dis: false },
            { title: "Адм спец", icon: "mdi-account-settings", to: "/admspec", dis: false },
            {
              title: "Страны",
              icon: "mdi-checkbox-marked-circle",
              to: "/country", dis: false
            },
            { title: "Роли", icon: "mdi-checkbox-marked-circle", to: "/role", dis: false },
            { title: "Уровни", icon: "mdi-checkbox-marked-circle", to: "/level", dis: false },
            { title: "Язык", icon: "mdi-checkbox-marked-circle", to: "/lang", dis: false },
            {
              title: "Компании",
              icon: "mdi-checkbox-marked-circle",
              to: "/company",
              dis: false
            },
            {
              title: "Правила",
              icon: "mdi-checkbox-marked-circle",
              to: "/rules/edit",
              dis: false
            },
            // {
            //   title: "Проекты",
            //   icon: "mdi-checkbox-marked-circle",
            //   to: "https://t.me/itrequests",
            // },
            {
              title: "Проекты",
              icon: "mdi-checkbox-marked-circle",
              to: "/admin/project",
              dis: false
            },
            {
              title: "Синхронизация",
              icon: "mdi-checkbox",
              to: "/admin/synchronization",
              dis: false
            },

            {
              title: "Отчет по регистрации",
              icon: "mdi-checkbox",
              to: "/admin/log/reg",
              dis: false
            },
            
          ];
        }
        }
        
        
        
      } else {
        state.ListHeaders = [
          { text: "id", value: "id" },
          {
            text: "Имя",
            value: "FIO",
          },
          { text: "Гражданство", value: "Country" },
          { text: "Английский", value: "English" },
          { text: "Уровень", value: "Level" },
          { text: "Роль", value: "Role" },
          { text: "Ставка", value: "Rate_RUB" },
          { text: "Навыки", value: "Main_skills" },
          { text: "Статус CV", value: "st" },
          { text: "Доступен с", value: "Lastcheck" },
          { text: "", value: "act" },
        ];
        let m = localStorage.getItem("b-hire-user-ddf"); 
        if (m ==2){
          state.ListMenu = [
            {
              title: "Специалисты",
              icon: "mdi-account-multiple",
              to: "/cabinet",
              dis: false,
            },
            
            { title: "Компания", icon: "mdi-file-document", to: "/firm", dis: false },
            //{ title: "Менеджеры", icon: "mdi-account-settings", to: "/meneger" },
            { title: "Профиль", icon: "mdi-settings-outline", to: "/account", dis: false  },
            { title: "Интервью", icon: "mdi-file-document", to: "/cabinet/interview", dis: false },
            { title: "Отчет", icon: "mdi-settings-outline", to: "/#", dis: true  },
            { title: "Воронка", icon: "mdi-settings-outline", to: "/#", dis: true  },
            { title: "Документы", icon: "mdi-settings-outline", to: "/#", dis: true  },
            //{ title: "Проекты", icon: "mdi-help", to: "", link: "https://t.me/itrequests" },
  
            //{ title: "Проекты", icon: "mdi-help", to: "/supplier/project" },
  
            //{ title: "Поддержка", icon: "mdi-help", to: "/setting" },
          ];
        } else {
          state.ListMenu = [
          {
            title: "Специалисты",
            icon: "mdi-account-multiple",
            to: "/cabinet",
            dis: true,
          },
          { title: "Компания", icon: "mdi-file-document", to: "/firm", dis: false },
          //{ title: "Менеджеры", icon: "mdi-account-settings", to: "/meneger" },
          { title: "Профиль", icon: "mdi-settings-outline", to: "/account", dis: false  },
          { title: "Отчет", icon: "mdi-settings-outline", to: "/#", dis: true  },
          { title: "Воронка", icon: "mdi-settings-outline", to: "/#", dis: true  },
          { title: "Документы", icon: "mdi-settings-outline", to: "/#", dis: true  },
          //{ title: "Проекты", icon: "mdi-help", to: "", link: "https://t.me/itrequests" },

          //{ title: "Проекты", icon: "mdi-help", to: "/supplier/project" },

          //{ title: "Поддержка", icon: "mdi-help", to: "/setting" },
        ];
        }
        
      }
    },
    onLoad(state, payload) {
      state.load = payload;
    },
    UpdateListSpecialist(state, payload) {
      state.ListSpecialist = payload.message;
    },
    UpdateListLocation(state, payload) {
      console.log('country')
      console.log(payload)
      for (let index = 0; index < payload.length; index++) {
        if(payload[index].inVisible == 1) {
          state.ListLocation.push(payload[index].location);
        }        
        state.ListLocationAct.push(payload[index].location);
      }
      state.ListLocationEdit = payload;
      //console.log("Fol");
      //console.log(state.ListLocationAct);
      //console.log("kol");
      //console.log(state.ListLocation);
      state.ListTaxId = payload;
    },
    UpdateListLangLevel(state, payload) {
      for (let index = 0; index < payload.length; index++) {
        state.ListLangLevel.push(payload[index].nameLevel);
      }
      state.ListLangLevelEdit = payload;
    },
    UpdateListContryTimeZone(state, payload) {
      for (let index = 0; index < payload.length; index++) {
        state.ListContryTimeZone.push(payload[index].nm);
      }
      //console.log("countryToTimeZone");
      //console.log(state.ListContryTimeZone);
    },
    UpdateListLang(state, payload) {
      for (let index = 0; index < payload.length; index++) {
        state.ListLang.push(payload[index].nameLang);
      }
      state.ListLangEdit = payload;
    },
    UpdateListLevel(state, payload) {
      for (let index = 0; index < payload.length; index++) {
        state.ListLevel.push(payload[index].nameLevel);
      }
      state.ListLevelEdit = payload;
    },

    UpdateListIndustries(state, payload) {
      for (let index = 0; index < payload.length; index++) {
        state.ListIndustries.push(payload[index].nameIndustries);
      }
      state.ListIndustriesEdit = payload;
    },

    UpdateListLevelEducation(state, payload) {
      for (let index = 0; index < payload.length; index++) {
        state.ListLevelEducation.push(payload[index].nameLevelEducation);
      }
      state.ListLevelEducationEdit = payload;
    },

    UpdateListEducation(state, payload) {
      for (let index = 0; index < payload.length; index++) {
        state.ListEducation.push(payload[index].nameEducation);
      }
      state.ListEducationEdit = payload;
    },

    UpdateListRole(state, payload) {
      for (let index = 0; index < payload.length; index++) {
        state.ListRole.push(payload[index].nameRole);
      }
      ////console.log(payload);
      state.ListRoleEdit = payload;
    },
    UpdateListSkills(state, payload) {
      for (let index = 0; index < payload.length; index++) {
        state.ListSkills.push(payload[index].nameSkils);
      }
      state.ListSkillsEdit = payload;
    },
    UpdateListTypeCompany(state, payload) {
      for (let index = 0; index < payload.length; index++) {
        state.ListTypeCompany.push(payload[index].namePerson);
      }
      state.ListTypeCompanyEdit = payload;
    },
    UpdateListCategories(state, payload) {
      for (let index = 0; index < payload.length; index++) {
        state.ListCategories.push(payload[index].nameCategories);
      }
      state.ListCategoriesEdit = payload;
    },
    UpdateListCountPeople(state, payload) {
      for (let index = 0; index < payload.length; index++) {
        state.ListPeopleCount.push(payload[index].nameCount);
      }
      state.ListPeopleCountEdit = payload;
    },
    UpdateListTimeZone(state, payload) {
      for (let index = 0; index < payload.length; index++) {
        state.ListTimeZone.push(payload[index].nameTimeZone);
      }
      state.ListTimeZoneEdit = payload;
    },
    UpdateListFormOrg(state, payload) {
      for (let index = 0; index < payload.length; index++) {
        state.ListFormOrg.push(payload[index].nameFormOrg);
      }
      state.ListFormOrgEdit = payload;
    },
    UpdateCVSpec(state, payload) {
      state.PersonDataCV = payload;
    },
    updateListExp(state, payload) {
      state.ListExp.push(payload);
    },

    UpdateListCompany(state, payload) {
      for (let index = 0; index < payload.length; index++) {
        state.ListCompany.push(payload[index].nameFirm);
      }
      state.ListCompanyEdit = payload;
    },
    UpdateUrlFoto(state, payload) {
      state.baseUrl = Axios.defaults.baseURL + "/public/images/" + payload;
    },
    saveDataCompany(state, payload){
      state.CompanyData = payload;
      //console.log(state.CompanyData)
    }
  },
  actions: {
    getListMainClient({ commit }, payload) {
      //console.log(payload);
      commit("onLoad", true);
      return new Promise((resolve, reject) => {
        Axios.post("/api/v1/account/auth", {
          zn: payload,
        })
          .then((res) => {
            //console.log(res.data);
            if (res.data.message.message != "Успешно") {
              alert("Не верный логин и пароль!");
              commit("onLoad", false);
            } else {
              commit("onLoad", false);
              commit("setAuth", true);
              commit("setUserAuth", new User(res.data.message.jwt));
              commit("UpdateMenuList", res.data.message.st);
              Axios.defaults.headers.common["Authorization"] = res.data.message.jwt;
              localStorage.setItem("b-hire-user-token", res.data.message.jwt);
              localStorage.setItem("b-hire-user-status", res.data.message.st);
              localStorage.setItem("b-hire-user-fio", res.data.message.fio);
              localStorage.setItem("b-hire-user-eml", res.data.message.eml);
              localStorage.setItem("b-hire-user-name", res.data.message.nm);
              localStorage.setItem("b-hire-user-ddf", res.data.message.moder);
              localStorage.setItem("b-hire-user-link", "0");
              resolve(res.data.message.st);
            }
          })
          .catch((res) => {
            alert("Вход не возможен! Возможные причины: \n неверны логин или пароль \n не активирована данная запись")
            ////console.log(res);
            console.log("error");
            commit("onLoad", false);
            reject(res);
          });
      });
    },

    autoLoginUser({ commit }) {
      Axios.defaults.headers.common["Authorization"] =
        localStorage.getItem("b-hire-user-token");
      commit(
        "setUserAuth",
        new User(localStorage.getItem("b-hire-user-token"))
      );
      commit("setAuth", true);
      commit("UpdateMenuList", localStorage.getItem("b-hire-user-status"));
    },

    getExitClient({ commit }) {
      //console.log("ex");
      localStorage.removeItem("b-hire-user-token");
      localStorage.removeItem("b-hire-user-status");
      localStorage.removeItem("b-hire-user-fio");
      localStorage.removeItem("b-hire-user-name");
      localStorage.removeItem("b-hire-user-link");
      //console.log("ex1");
      //console.log("exit");
      commit("setUserAuth", null);
      commit("setAuth", false);
    },

    saveCompanyPageOne({commit}, payload){
      commit("onLoad", true);
      commit("saveDataCompany", payload);
      Axios.post("api/v1/account/register/log", {
        zn: payload
      })
        .then(() => {
          commit("onLoad", false);
        })
        .catch(() => {
          console.log("error");
        });
    },

    async setNewUser({ commit, state}, payload) {
      if(state.CompanyData != ''){
        commit("onLoad", true);
        Axios.post("api/v1/account/register/log", {
          zn: payload
        })
          .then(() => {
            console.log('dd');
          })
          .catch(() => {
            console.log("error");
          });
        //console.log(payload);
        Object.assign(state.CompanyData, payload)
        //console.log(state.CompanyData);
        return await new Promise((resolve, reject) => {
           Axios.post("/api/v1/account/register", {
            zn: state.CompanyData,
          })
            .then((res) => {
              console.log(res.data);
              commit("onLoad", false);
              if (res.data.message != "успешно") {
                alert(res.data.message);
                resolve(false);
              } else {
                resolve(true);
              }            
              
            })
            .catch(() => {
              console.log("error");
              reject(false);
            });
        });
      } else {
        alert('Для регистрации не указаны данные компании!')
      }
      
    },

     synchronizationSpec({commit}){
      commit("onLoad", true);
      return new Promise((resolve, reject) => {
        Axios.post("/api/v1/synchronization/specialist")
          .then((res) => {
            //console.log(res.data);
            commit("onLoad", false);
            if (res.data.status == 'ok') {
              let y = 'всего добавлено '+res.data.message.new + '; всего обновлено '+res.data.message.update;
              //console.log(y);
              commit("UpdateListError", res.data.message.error);
              resolve(y);
            } else {
              resolve("Ошибка синхронизации");
            }            
            
          })
          .catch(() => {
            console.log("error");
            reject(false);
          });
      });
    },

    synchronizationCom({commit}){
      commit("onLoad", true);
      return new Promise((resolve, reject) => {
        Axios.post("/api/v1/synchronization/company")
          .then((res) => {
            //console.log(res.data);
            commit("onLoad", false);
            if (res.data.status == 'ok') {
              let y = 'всего добавлено '+res.data.message.new + '; всего обновлено '+res.data.message.update;
              //console.log(y);
              resolve(y);
            } else {
              resolve("Ошибка синхронизации");
            }            
            
          })
          .catch(() => {
            console.log("error");
            reject(false);
          });
      });
    },

    setDataAccUser({ commit }, payload){
      commit("onLoad", true);
      //console.log(payload);
      return new Promise((resolve, reject) => {
        Axios.post("/api/v1/account/newDataUser", {
          zn: payload,
        })
          .then((res) => {
            //console.log(res.data);
            localStorage.setItem("b-hire-user-eml", res.data.message[0].eml);
            localStorage.setItem("b-hire-user-fio", payload.fio);
            if (res.data.message != []) {
              alert("Вы успешно сменили данные!");
            } else {
              alert("Не удалось сохранить данные!");
            }
            commit("onLoad", false);
            resolve(res.data.message[0].eml);
          })
          .catch(() => {
            console.log("error");
            reject(false);
          });
      });
    },
    ActivateNewUser({ commit }, payload) {
      commit("onLoad", true);
      //console.log(payload);
      return new Promise((resolve, reject) => {
        Axios.post("/api/v1/account/activate", {
          zn: payload,
        })
          .then((res) => {
            //console.log(res.data);
            let t = false;
            if (res.data.message == "Успешно") {
              t=true;
            } 
            commit("onLoad", false);
            resolve(t);
          })
          .catch(() => {
            console.log("error");
            reject(false);
          });
      });
    },
    getSpecialistList({ commit }) {
      commit("onLoad", true);
      Axios.post("/api/v1/personal/List")
        .then((res) => {
          //console.log(res.data);
          commit("UpdateListSpecialist", res.data);
          commit("onLoad", false);
        })
        .catch(() => {
          console.log("error");
        });
    },

    getMailUser({commit}){
      commit("onLoad", true);
      return new Promise((resolve) => {
        Axios.post("/api/v1/personal/info")
        .then((res) => {
        let r = localStorage.getItem("b-hire-user-eml");
        let y = localStorage.getItem("b-hire-user-fio");
        commit("onLoad", false);
        //console.log("sdfsf");
        //console.log(res);
        resolve({eml:r, fio:y, tel: res.data.message[0].telegram});
      })
      .catch(() => {
        console.log("error");
      });
      });
    },

    findInnBase({commit}, payload){
      commit("onLoad", true);
      return new Promise((resolve, reject) => {
        Axios.post("/api/v1/account/find/inn", {
          zn: payload,
        })
          .then((res) => {
            //console.log("inn find");
            //console.log(res.data);
            let t = false;
            if (res.data.message != false) {
              t=true;
            } 
            commit("onLoad", false);
            resolve(t);
          })
          .catch(() => {
            console.log("error");
            reject(false);
          });
      });
    },

    findEmailFirm({commit}, payload){
      commit("onLoad", true);
      return new Promise((resolve, reject) => {
        Axios.post("/api/v1/account/find/email", {
          zn: payload,
        })
          .then((res) => {
            ////console.log("email find");
            ////console.log(res.data);
            let t = false;
            if (res.data.message != false) {
              t=true;
            } 
            commit("onLoad", false);
            resolve(t);
          })
          .catch(() => {
            console.log("error");
            reject(false);
          });
      });
    },


    getFullInfoCompany({commit}, payload){
      commit("onLoad", true);
      return new Promise((resolve, reject) => {
        Axios.post("/api/v1/company/full/info/get", {
          zn: payload,
        })
          .then((res) => {
            ////console.log("email find");
            ////console.log(res.data);s
            commit("onLoad", false);
            resolve(res.data.message);
          })
          .catch(() => {
            console.log("error");
            reject(false);
          });
      });
    },

    getNewPassCompany({commit}, payload){
      commit("onLoad", true);
      return new Promise((resolve, reject) => {
        Axios.post("/api/v1/company/pass/new/get", {
          zn: payload,
        })
          .then((res) => {
            console.log("pass");
            console.log(res.data);
            commit("onLoad", false);
            resolve(res.data.message);
          })
          .catch(() => {
            console.log("error");
            reject(false);
          });
      });
    },

    findSpecIDbx({commit}, payload){
      commit("onLoad", true);
      return new Promise((resolve, reject) => {
        Axios.post("/api/v1/spec/find/id/get", {
          zn: payload,
        })
          .then((res) => {
            console.log("pass");
            console.log(res.data);
            commit("onLoad", false);
            resolve(res.data.message);
          })
          .catch(() => {
            console.log("error");
            reject(false);
          });
      });
    },


    getCountryToTimeZone({ commit }) {
      commit("onLoad", true);
      Axios.post("/api/v1/handbk/ContryToTimeZone")
        .then((res) => {
          ////console.log("countryToTimeZone");
          ////console.log(res.data);
          commit("UpdateListContryTimeZone", res.data.message);
          commit("onLoad", false);
        })
        .catch(() => {
          console.log("error");
        });
    },

    getLevelEducationList({ commit }) {
      //commit("onLoad", true);
      Axios.post("/api/v1/handbk/education/level")
        .then((res) => {
          //console.log("education");
          //console.log(res.data);
          //commit("onLoad", false);
          commit("UpdateListLevelEducation", res.data.message);
        })
        .catch(() => {
          console.log("error");
        });
    },
    getEducationList({ commit }) {
      //commit("onLoad", true);
      Axios.post("/api/v1/handbk/education")
        .then((res) => {
          //console.log("education");
          //console.log(res.data);
          //commit("onLoad", false);
          commit("UpdateListEducation", res.data.message);
        })
        .catch(() => {
          console.log("error");
        });
    },

    getCompanyList({ commit }) {
      //commit("onLoad", true);
      Axios.post("/api/v1/Company/List")
        .then((res) => {
          //console.log('get list company')
          //console.log(res.data);
          //commit("onLoad", false);
          commit("UpdateListCompany", res.data.message);
        })
        .catch(() => {
          console.log("error");
        });
    },

    updateRaitingCompany({commit}, payload){
      Axios.post("/api/v1/Company/raiting/update", {
        zn: payload
      })
        .then((res) => {
          //console.log('get list company')
          //console.log(res.data);
          //commit("onLoad", false);
          commit("UpdateListCompany", res.data.message);
        })
        .catch(() => {
          console.log("error");
        });
    },

    SaveCommitCompany({commit}, payload){
      Axios.post("/api/v1/Company/commit/save", {
        zn: payload
      })
        .then((res) => {
          //console.log('get list company')
          //console.log(res.data);
          //commit("onLoad", false);
          commit("UpdateListCompany", res.data.message);
        })
        .catch(() => {
          console.log("error");
        });
    },


setCompanyBlock({ commit }, payload) {
      //commit("onLoad", true);
      Axios.post("/api/v1/Company/List/block", {
        id: payload
      })
        .then((res) => {
          commit("UpdateListCompany", res.data.message);
        })
        .catch(() => {
          console.log("error");
        });
    },
    
    getLocationList({ commit, state }) {
      if(state.ListLocation.length == 0) {
         Axios.post("/api/v1/handbk/location")
        .then((res) => {
          //console.log(res.data);
          commit("onLoad", false);
          commit("UpdateListLocation", res.data.message);
        })
        .catch(() => {
          console.log("error");
        });
      }     
    },

    getLocationListActive({ commit }) {
      console.log('location dd');
      //if(state.ListLocation.length == 0) {
         Axios.post("api/v1/handbk/location/active")
        .then((res) => {
          console.log('location dd');
          console.log(res.data);
          commit("onLoad", false);
          commit("UpdateListLocation", res.data.message);
        })
        .catch(() => {
          console.log("error");
        });
      //}     
    },
    getLangLevelList({ commit }) {
      //commit("onLoad", true);
      Axios.post("/api/v1/handbk/lang/level")
        .then((res) => {
          //console.log(res.data);
          //commit("onLoad", false);
          commit("UpdateListLangLevel", res.data.message);
        })
        .catch(() => {
          console.log("error");
        });
    },
    getLangList({ commit }) {
      //commit("onLoad", true);
      Axios.post("/api/v1/handbk/lang")
        .then((res) => {
          //console.log(res.data);
          //commit("onLoad", false);
          commit("UpdateListLang", res.data.message);
        })
        .catch(() => {
          console.log("error");
        });
    },
    getLevelList({ commit }) {
      //commit("onLoad", true);
      Axios.post("/api/v1/handbk/level")
        .then((res) => {
          //console.log(res.data);
          //commit("onLoad", false);
          commit("UpdateListLevel", res.data.message);
        })
        .catch(() => {
          console.log("error");
        });
    },
    setFotoSpec({ commit }, payload) {
      //console.log(payload);
      let formData = new FormData();
      formData.append("file", payload);
      commit("onLoad", true);
      Axios.post("/api/v1/handbk/foto", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then(function (res) {
          //console.log(res);
          if (res.data.message == "error") {
            alert("Ошибка загрузки файла!");
          } else {
            commit("UpdateUrlFoto", res.data.message);
            commit("onLoad", false);
          }

          //console.log("SUCCESS!!");
        })
        .catch(function (res) {
          console.log(res);
          //console.log("FAILURE!!");
        });
    },
    getRoleList({ commit }) {
      //commit("onLoad", true);
      Axios.post("/api/v1/handbk/role")
        .then((res) => {
          //console.log(res.data);
          //commit("onLoad", false);
          commit("UpdateListRole", res.data.message);
        })
        .catch(() => {
          console.log("error");
        });
    },
    getIndustriesList({ commit }) {
      //commit("onLoad", true);
      Axios.post("/api/v1/handbk/industries/get")
        .then((res) => {
          //console.log(res.data);
          //commit("onLoad", false);
          commit("UpdateListIndustries", res.data.message);
        })
        .catch(() => {
          console.log("error");
        });
    },
    getSkillsList({ commit }) {
      //commit("onLoad", true);
      Axios.post("/api/v1/handbk/skills")
        .then((res) => {
          //console.log(res.data);
          //commit("onLoad", false);
          commit("UpdateListSkills", res.data.message);
        })
        .catch(() => {
          console.log("error");
        });
    },
    saveSkill({ commit }, payload) {
      //commit("onLoad", true);
      Axios.post("/api/v1/handbk/skills/save", {
        zn: payload,
      })
        .then((res) => {
          //console.log(res.data);
          commit("onLoad", false);
          commit("UpdateListSkills", res.data.message);
        })
        .catch(() => {
          console.log("error");
        });
    },
    saveRole({ commit }, payload) {
      //commit("onLoad", true);
      Axios.post("/api/v1/handbk/role/save", {
        zn: payload,
      })
        .then((res) => {
          //console.log(res.data);
          //commit("onLoad", false);
          commit("UpdateListRole", res.data.message);
        })
        .catch(() => {
          console.log("error");
        });
    },
    getPersonCV({ commit }, payload) {
      commit("onLoad", true);
      return new Promise((resolve, reject) => {
        Axios.post("/api/v1/personal/cv", {
          id: payload,
        })
          .then((res) => {
            //console.log("personalCV");
            //console.log(res.data);
            commit("onLoad", false);
            commit("UpdateCVSpec", res.data.message[0]);
            resolve();
          })
          .catch(() => {
            console.log("error");
            commit("onLoad", false);
            reject();
          });
      });
    },
    saveCountry({ commit }, payload) {
      //commit("onLoad", true);
      Axios.post("/api/v1/handbk/country/save", {
        zn: payload,
      })
        .then((res) => {
          //console.log(res.data);
          commit("onLoad", false);
          commit("UpdateListLocation", res.data.message);
        })
        .catch(() => {
          console.log("error");
        });
    },

    saveLang({ commit }, payload) {
      //commit("onLoad", true);
      Axios.post("/api/v1/handbk/lang/save", {
        zn: payload,
      })
        .then((res) => {
          //console.log(res.data);
          commit("onLoad", false);
          commit("UpdateListLang", res.data.message);
        })
        .catch(() => {
          console.log("error");
        });
    },
    saveLevel({ commit }, payload) {
      //commit("onLoad", true);
      Axios.post("/api/v1/handbk/level/save", {
        zn: payload,
      })
        .then((res) => {
          //console.log(res.data);
          commit("onLoad", false);
          commit("UpdateListLevel", res.data.message);
        })
        .catch(() => {
          console.log("error");
        });
    },
    saveCompany({ commit }, payload) {
      //commit("onLoad", true);
      Axios.post("/api/v1/firm/company/save", {
        zn: payload,
      })
        .then((res) => {
          //console.log(res.data);
          commit("onLoad", false);
          commit("UpdateListCompany", res.data.message);
        })
        .catch(() => {
          console.log("error");
        });
    },
   
    getTypeCompanyList({ commit, state }) {
      if(state.ListTypeCompany.length == 0) {
        Axios.post("/api/v1/handbk/firm/type/get")
        .then((res) => {
          //console.log(res.data);
          
          commit("UpdateListTypeCompany", res.data.message);
        })
        .catch(() => {
          console.log("error");
        });
      }
      
    },
    getCountPeopleCompany({ commit }) {
      //commit("onLoad", true);
      Axios.post("/api/v1/handbk/countPeopleFirm/get")
        .then((res) => {
          //console.log(res.data);
          //commit("onLoad", false);
          commit("UpdateListCountPeople", res.data.message);
        })
        .catch(() => {
          console.log("error");
        });
    },
    getFormOrganization({ commit }) {
      //commit("onLoad", true);
      Axios.post("/api/v1/handbk/formOrg/get")
        .then((res) => {
          //console.log(res.data);
          //commit("onLoad", false);
          commit("UpdateListFormOrg", res.data.message);
        })
        .catch(() => {
          console.log("error");
        });
    },
    getTimeZone({ commit }) {
      //commit("onLoad", true);
      Axios.post("/api/v1/handbk/timeZone/get")
        .then((res) => {
          //console.log(res.data);
          //commit("onLoad", false);
          commit("UpdateListTimeZone", res.data.message);
        })
        .catch(() => {
          console.log("error");
        });
    },
    getCategories({ commit }) {
      //commit("onLoad", true);
      Axios.post("/api/v1/handbk/Categories/get")
        .then((res) => {
          //console.log(res.data);
          //commit("onLoad", false);
          commit("UpdateListCategories", res.data.message);
        })
        .catch(() => {
          console.log("error");
        });
    },

    

    getCategoriesAction({ commit }) {
      //commit("onLoad", true);
      Axios.post("/api/v1/handbk/Categories/get/action")
        .then((res) => {
          //console.log(res.data);
          //commit("onLoad", false);
          commit("UpdateListCategories", res.data.message);
        })
        .catch(() => {
          console.log("error");
        });
    },
    blockCompany({ commit }, payload) {
      //commit("onLoad", true);
      Axios.post("/api/v1/firm/company/block", {
        id: payload,
      })
        .then((res) => {
          //console.log(res.data);
          commit("onLoad", false);
          commit("UpdateListCompany", res.data.message);
        })
        .catch(() => {
          console.log("error");
        });
    },
    genPassCompany({ commit }, payload) {
      commit("onLoad", true);
      Axios.post("/api/v1/firm/company/pass", {
        id: payload,
      })
        .then((res) => {
          //console.log(res.data);
          commit("onLoad", false);
          alert(res.data.message);
        })
        .catch(() => {
          console.log("error");
        });
    },
    saveAdmSepc({ commit }, payload) {
      commit("onLoad", true);
      //console.log(payload);
      Axios.post("/api/v1/personal/adm/save", {
        zn: payload,
      })
        .then((res) => {
          console.log(res.data);
          commit("onLoad", false);
          //commit("UpdateListSpecialist", res.data);
        })
        .catch(() => {
          console.log("error");
        });
    },
    AddListExp({ commit }, payload) {
      commit("updateListExp", payload);
    },
  },
  getters: {
    getAuthClient(state) {
      return state.avt;
    },
    getOnLoad(state) {
      return state.load;
    },
    getListSpecialist(state) {
      return state.ListSpecialist;
    },
    getListLocation(state) {
      return state.ListLocation;
    },
    getListLocationAll(state) {
      return state.ListLocationAct;
    },
    getListLang(state) {
      return state.ListLang;
    },
    getListLangEdit(state) {
      return state.ListLangEdit;
    },
    getListLangLevel(state) {
      return state.ListLangLevel;
    },
    getListLangLevelEdit(state) {
      return state.ListLangLevelEdit;
    },
    getListLevel(state) {
      return state.ListLevel;
    },
    getListLevelEdit(state) {
      return state.ListLevelEdit;
    },
    getListRole(state) {
      return state.ListRole;
    },
    getListRate(state) {
      return state.ListRate;
    },
    getListPub(state) {
      return state.ListPub;
    },
    getListSkills(state) {
      return state.ListSkills;
    },
    getPersonCV(state) {
      return state.PersonDataCV;
    },
    getListSkillsEdit(state) {
      return state.ListSkillsEdit;
    },
    getListLocationEdit(state) {
      return state.ListLocationEdit;
    },
    getListRoleEdit(state) {
      return state.ListRoleEdit;
    },
    getListExp(state) {
      return state.ListExp;
    },
    getListCompany(state) {
      return state.ListCompany;
    },
    getListCompanyEdit(state) {
      return state.ListCompanyEdit;
    },
    getListTypeCompany(state) {
      return state.ListTypeCompany;
    },
    getListTypeCompanyEdit(state) {
      return state.ListTypeCompanyEdit;
    },
    getListPeopleCount(state) {
      return state.ListPeopleCount;
    },
    getListPeopleCountEdit(state) {
      return state.ListPeopleCountEdit;
    },
    getListFormOrg(state) {
      return state.ListFormOrg;
    },
    getListFormOrgEdit(state) {
      return state.ListFormOrgEdit;
    },
    getTimeZone(state) {
      return state.ListTimeZone;
    },
    getTimeZoneEdit(state) {
      state.ListTimeZoneEdit;
    },
    getListCategories(state) {
      return state.ListCategories;
    },
    getListCategoriesEdit(state) {
      return state.ListCategoriesEdit;
    },
    getBaseUrl(state) {
      return state.baseUrl;
    },
    getListIndustries(state) {
      return state.ListIndustries;
    },
    getListIndustriesEdit(state) {
      return state.ListIndustriesEdit;
    },
    getListLevelEducation(state) {
      return state.ListLevelEducation;
    },
    getListLevelEducationEdit(state) {
      return state.ListLevelEducationEdit;
    },
    getListEducation(state) {
      return state.ListEducation;
    },
    getListEducationEdit(state) {
      return state.ListEducationEdit;
    },
    getOnListContryTimeZone(state) {
      return state.ListContryTimeZone;
    },
    getListMenu(state) {
      return state.ListMenu;
    },
    getListheader(state) {
      return state.ListHeaders;
    },
    getStatusUser(state) {
      return state.StatusUser;
    },
    ListError(state) {
      return state.ListError;
    },
    ListTaxId(state) {
      return state.ListTaxId;
    },
  },
});
