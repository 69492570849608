<template>
<div style="margin: auto;">
    <v-dialog v-model="dialog" persistent max-width="1200px">
        <v-card>
            <v-card-title>
                <span class="text-h5">История отправок</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-data-table :headers="headers" :items="desserts" :items-per-page="5" class="elevation-1">
                        <template v-slot:item.status="{ item}">
                            <span v-if="item.status == 1">отправлено</span>
                            <span v-else>ожидает</span>
                        </template>
                    </v-data-table>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" @click="dialog = false">
                    Закрыть
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <loader v-if="load" object="#ff9633" color1="#ffffff" color2="#17fd3d" size="5" speed="2" bg="#343a40" objectbg="#999793" opacity="80" name="dots"></loader>
    <v-row>
        <v-col cols="12">
            <h2>Работа с ботом</h2>
        </v-col>
    </v-row>
    <v-row style="margin: auto; width: 800px;">
        <v-col cols="6" md="6" sm="6" xs="6">
            <v-autocomplete :items="categories" deletable-chips outlined hide-details="auto" dense chips small-chips multiple label="Роль" v-model="rl" @change="onFind"></v-autocomplete>
        </v-col>
        <v-col cols="6" md="6" sm="6" xs="6">
            <v-autocomplete :items="['1 уровень','2 уровень','3 уровень',]" deletable-chips outlined hide-details="auto" dense chips small-chips label="Уровень партнера" multiple v-model="lvl" @change="onFind"></v-autocomplete>
        </v-col>
    </v-row>
    <v-row style="margin: auto; width: 800px;">
        <v-col cols="12">
            <v-autocomplete :items="list" v-model="dt.id" dense chips multiple label="пользователи"></v-autocomplete>
        </v-col>
        <v-col cols="12">
            <v-checkbox v-model="checkbox" label="Выбрать всех" @change="Alert('ddd')"></v-checkbox>
        </v-col>

        <v-col cols="12">
            <v-textarea name="input-7-1" label="Текст сообщения" hint="Введите текс сообщения" v-model="dt.text"></v-textarea>

        </v-col>
        <v-col cols="12">
            <v-file-input reg="file" accept="image/png, image/jpeg, image/bmp" label="File input" id="file" ref="file" v-on:change="handleFileUpload()" v-model="file"></v-file-input>
        </v-col>
        <v-col cols="12">

            <v-img max-width="200" :src="dt.foto" style="margin: auto;"></v-img>
        </v-col>
        <v-col cols="6">
            <v-btn @click="onPost">Запустить</v-btn>
        </v-col>
        <v-col cols="6">
            <v-btn @click="onHistory">История</v-btn>
        </v-col>
    </v-row>

</div>
</template>

<script>
export default {
    name: "deal",
    data: () => ({
        dt: {
            img: "",
            foto: "",
            text: "",
            id: [],
        },
        dialog: false,
        rl: [],
        lvl: [],
        checkbox: false,
        file: '',
        headers: [{
                text: 'id',
                value: 'id',
            },
            {
                text: 'Сообщение',
                value: 'message'
            },
            {
                text: 'Группы',
                value: 'listGroup'
            },
            {
                text: 'Статус',
                value: 'status'
            },
            {
                text: 'Не смог отправить',
                value: 'result_send'
            },
        ],
        desserts: [{
            id: 'Frozen Yogurt',
            message: 159,
            status: 6.0,
            result_send: 24,
        }, ],
    }),
    computed: {
        list() {
            return this.$store.getters.getListFirmBot;
        },
        load() {
            return this.$store.getters.getOnLoad;
        },
        categories() {
            return this.$store.getters.getListCategories;
        },
    },
    mounted() {
        this.$store.dispatch("getBotListFirm", this.dt);
        this.$store.dispatch("getCategories");
    },
    methods: {
        onPost() {
            console.log(this.dt);
            if (this.checkbox == true) {
                this.dt.dt = [];
                for (let index = 0; index < this.list.length; index++) {
                    this.dt.id.push(this.list[index]);

                }
            }
            this.$store.dispatch("SentBot", this.dt);
        },
        handleFileUpload() {
            console.log(this.file)
            this.$store.dispatch("SentBotImg", this.file).then(res => {
                this.dt.foto = 'https://humacl.x-or.ru/public/bot/' + res
                this.dt.img = res
            });
        },
        onFind() {
            let t = {
                rl: this.rl,
                lvl: this.lvl,
            }
            this.$store.dispatch("FindBot", t);
        },
        onHistory() {
            this.dialog = true
            this.$store.dispatch("getHistoryBot").then(res => {
                this.desserts = res
            })
        }

    },
};
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.infoPerson {
    text-align: left;
}
</style>
